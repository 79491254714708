export default {
  checkAdmin(next) {
    const user = JSON.parse(localStorage.getItem("user"));
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && user.role !== "user") {
      next();
    } else {
      next("/");
    }
  },
  checkUser(next) {
    const user = JSON.parse(localStorage.getItem("user"));
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && user.role === "user") {
      next();
    } else {
      next("/");
    }
  },
};
