<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},

  data: () => ({}),
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
@import "./styles/global.scss";
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}
.v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}
.v-application {
  font-family: "Inter", sans-serif !important;
}
.v-btn {
  text-transform: inherit !important;
}
.detectItem {
  background: rgb(184, 216, 184);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 100% !important;
  padding: 4px 16px !important;
}
.v-skeleton-loader__table-cell {
  height: 30px !important;
}
.mx-input {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px !important;
  height: 35px !important;
  /*border-top: none !important;
  border-left: none !important;
  border-right: none !important;*/
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none;
  border: 1px solid gray !important;
}
.mx-datepicker-range {
  width: 240px !important;
}
/*.mx-datepicker {
  border: 1px solid gray !important;
}*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 14px !important;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
}
.v-text-field--outlined .v-label {
  top: 13px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 44px !important;
}
.v-text-field--outlined .v-label--active {
  transform: translateY(-20px) scale(0.75) !important;
  background: #fff;
  padding: 0 5px;
}
.v-text-field--enclosed .v-input__append-inner {
  margin-top: 12px !important;
}
.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
  align-items: center;
  gap: 20px;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.v-text-field--outlined fieldset {
  top: 0 !important;
}
.v-dialog__content {
  z-index: 1000 !important;
}
</style>
