import Vue from "vue";
import VueRouter from "vue-router";
import $user from "../utils/protectRoute";

const loadComponent = (path) => () => import(`@/views/${path}.vue`);

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: loadComponent("main/HomePage"),
  },
  {
    path: "/login",
    name: "login",
    component: loadComponent("auth/Login"),
  },
  {
    path: "/register",
    name: "register",
    component: loadComponent("auth/Register"),
  },
  {
    path: "/forgot-password",
    name: "restore",
    component: loadComponent("auth/RestorePassword"),
  },
  {
    path: "/verify",
    name: "verify",
    component: loadComponent("auth/Verify"),
  },
  {
    path: "/admin",
    name: "sAdminManage",
    redirect: "/admin/goods",
    component: loadComponent("admin/AdminManage"),
    children: [
      {
        path: "users",
        name: "users",
        component: loadComponent("admin/Users"),
        meta: {
          title: "Пользователи",
        },
      },
      {
        path: "analytics",
        name: "analytics",
        component: loadComponent("admin/Analytics"),
        meta: {
          title: "Аналитика",
        },
      },
      {
        path: "goods",
        name: "goods",
        component: loadComponent("admin/Applications"),
        meta: {
          title: "Отправки",
        },
      },
      {
        path: "regions",
        name: "regions",
        component: loadComponent("admin/Regions"),
        meta: {
          title: "Регионы",
        },
      },
      {
        path: "stocks",
        name: "stocks",
        component: loadComponent("admin/Stocks"),
        meta: {
          title: "На складе",
        },
      },
      {
        path: "receive-stocks",
        name: "receiveStocks",
        component: loadComponent("admin/ReceiveStocks"),
        meta: {
          title: "Прием товара",
        },
      },
      {
        path: "delivered",
        name: "delivered",
        component: loadComponent("admin/Delivered"),
        meta: {
          title: "Выданные",
        },
      },
      {
        path: "deliveredPackage",
        name: "deliveredPackage",
        component: loadComponent("admin/Packages"),
        meta: {
          title: "Полученные",
        },
      },
      {
        path: "deliveries",
        name: "deliveryPackage",
        component: loadComponent("admin/DeliveryPackages"),
        meta: {
          title: "Доставка",
        },
      },
      {
        path: "search",
        name: "search",
        component: loadComponent("admin/Search"),
        meta: {
          title: "Поиск",
        },
      },
      {
        path: "package_receive",
        name: "packageReceive",
        component: loadComponent("admin/DeliveredPackages"),
        meta: {
          title: "Товары клиента",
        },
      },
      {
        path: "delivery",
        name: "delivery",
        component: loadComponent("admin/Delivery"),
        meta: {
          title: "Выдача",
        },
      },
      {
        path: "payments",
        name: "payments",
        component: loadComponent("admin/Payments"),
        meta: {
          title: "Тип оплаты",
        },
      },
      {
        path: "news",
        name: "news",
        component: loadComponent("admin/News"),
        meta: {
          title: "Новости",
        },
      },
      {
        path: "stories",
        name: "stories",
        component: loadComponent("admin/Stories"),
        meta: {
          title: "Сторисы",
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      $user.checkAdmin(next);
    },
  },
  {
    path: "/user",
    name: "userManage",
    redirect: "/user/goods",
    component: loadComponent("user/UserManage"),
    children: [
      {
        path: "goods",
        name: "userGoods",
        component: loadComponent("user/Goods"),
        meta: {
          title: "Мои заказы",
        },
      },
      {
        path: "profile",
        name: "userProfile",
        component: loadComponent("user/Profile"),
        meta: {
          title: "Мои профиль",
        },
      },
      {
        path: "delivery",
        name: "userDelivery",
        component: loadComponent("user/Delivery"),
        meta: {
          title: "Доставка по городу",
        },
      },
      {
        path: "search",
        name: "userSearch",
        component: loadComponent("user/Search"),
        meta: {
          title: "Поиск...",
        },
      },
      {
        path: "addresses",
        name: "userAddress",
        component: loadComponent("user/Addresses"),
        meta: {
          title: "Адреса...",
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      $user.checkUser(next);
    },
  },

  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/login",
    "/register",
    "/forgot-password",
    "/verify",
  ]; // , "/restore-pass"
  const authRequired = !publicPages.includes(to.path);
  const isLoggedIn = localStorage.getItem("accessToken");
  if (authRequired && !isLoggedIn) {
    return next("/");
  } else {
    next();
  }
});

export default router;
